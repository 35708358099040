exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-dynamic-url-js": () => import("./../../../src/pages/[...dynamic_url].js" /* webpackChunkName: "component---src-pages-dynamic-url-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-services-cloud-solutions-index-js": () => import("./../../../src/pages/services/cloud-solutions/index.js" /* webpackChunkName: "component---src-pages-services-cloud-solutions-index-js" */),
  "component---src-pages-services-data-solutions-index-js": () => import("./../../../src/pages/services/data-solutions/index.js" /* webpackChunkName: "component---src-pages-services-data-solutions-index-js" */),
  "component---src-pages-services-dynamic-url-js": () => import("./../../../src/pages/services/[...dynamic_url].js" /* webpackChunkName: "component---src-pages-services-dynamic-url-js" */),
  "component---src-pages-services-enterprise-solutions-index-js": () => import("./../../../src/pages/services/enterprise-solutions/index.js" /* webpackChunkName: "component---src-pages-services-enterprise-solutions-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mobility-solutions-index-js": () => import("./../../../src/pages/services/mobility-solutions/index.js" /* webpackChunkName: "component---src-pages-services-mobility-solutions-index-js" */),
  "component---src-pages-services-qa-testing-index-js": () => import("./../../../src/pages/services/qa-testing/index.js" /* webpackChunkName: "component---src-pages-services-qa-testing-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

